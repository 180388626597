<template>
  <div style="width: 100%; height: 100%;">
    <div class="plates">
      <div class="plate-left">
        <div class="pl-top">
          <div class="pl-font1 bolder letter">{{timeData.week || '--'}}</div>
          <div class="pl-font2">{{timeData.year || '--'}}年{{timeData.month || '--'}}月{{timeData.day || '--'}}日</div>
          <div class="pl-font2"><span class="iconfont icon-position pl-font2"></span><span
              style="display: inline-block; margin: 0 1vh;">{{location.province || '--'}}</span><span>{{location.city ||
              '--'}}</span></div>
        </div>
        <div class="pl-bottom">
          <div :class="ctrolIcon(now.text)" style="font-size: 10vh;"></div>
          <div class="pl-font1"><span style="display: inline-block; margin-right: 2vh;">{{now.temp || '--'}}</span>℃
          </div>
          <div class="pl-font3">{{now.text || '--'}}</div>
          <!-- <div class="pl-font3">今日穿衣指数：</div>
          <div class="pl-font3">短袖类</div>
          <div class="pl-font5">建议穿短衫、短裤等清凉夏季服装</div> -->
        </div>
      </div>
      <div class="plate-right">
        <div class="pr-top">
          <div class="pr-item">
            <p>空气质量</p>
            <p>--</p>
          </div>
          <!-- <div class="pr-item">
            <p>湿度</p>
            <p>--</p>
          </div> -->
          <div class="pr-item">
            <p>风向</p>
            <p>{{forecasts[checked].wd_day || '--'}}</p>
          </div>
          <div class="pr-item">
            <p>风速</p>
            <p>{{forecasts[checked].wc_day || '--'}}</p>
          </div>
          <div class="pr-item">
            <p>防晒指数</p>
            <p>--</p>
          </div>
          <div class="pr-item">
            <p>负氧离子浓度</p>
            <p>--</p>
          </div>
          <div class="pr-weatherbox">
            <div :class="ctrolWeather(index)" v-for="(item, index) in forecasts" :key="index"
              @click="clickWeather(index)">
              <div :class="[{'pr-font1':true},ctrolIcon(item.text_day)]"></div>
              <div class="pr-font2">{{item.week || '--'}}</div>
              <div class="pr-font3">{{item.high || '--'}}℃</div>
            </div>
          </div>
        </div>
        <div class="pr-bottom">
          <div class="mybutton">
            <!-- <div class="pr-button">
              <div class="pr-button-content"><span class="iconfont icon-position"
                  style="font-size: 4vh; display: inline-block; margin-right: 1vh; vertical-align: top;"></span><span
                  style="font-size: 2.5vh;">添加城市或者机场</span></div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { forecast } from '../../network/api.js'
  import { MP } from '../../assets/map.js'
  export default {
    name: 'envir',
    data() {
      return {
        ak: 'FRIVCEvys7yewbLXwZbYz0hGXobISN59',
        timeData: {
          year: null,
          month: null,
          day: null,
          week: null
        },
        location: {
          city: null,
          province: null
        },
        now: {
          temp: null,
          text: null,
        },
        forecasts: [{
          wd_day: '',
          wc_day: ''
        }],
        checked: 0,
      }
    },
    computed: {
      ctrolWeather() {
        return (index) => {
          return this.checked === index ? 'weather checked-weather' : 'weather'
        }
      },
      ctrolIcon() {
        return (value) => {
          if (value) {
            let className = 'iconfont icon-weizhitianqi'
            let wheatherobj = {
              晴: 'iconfont icon-tq_01qing',
              多云: 'iconfont icon-tq_03duoyun',
              阴: 'iconfont icon-tq_05yin',
              小雨: 'iconfont icon-tq_11xiaoyu',
            }
            Object.keys(wheatherobj).forEach(keys => {
              if (value.includes(keys)) {
                return className = wheatherobj[keys]
              }
            })
            return className
          } else {
            return false
          }

        }
      }
    },
    created() {
      //今天的日期
      this.timeData.year = new Date().getFullYear()
      this.timeData.month = new Date().getMonth() + 1
      this.timeData.day = new Date().getDate()
      // 今天是周几
      var day = new Date().getDay()
      var names = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      this.timeData.week = names[day]
    },
    mounted() {
      // this.getWeather()
      this.getCity()
    },
    methods: {
      getWeather(name) {
        let data = {
          city: name
        }
        forecast(data).then(res => {
          console.log(res)
          // 获取位置
          Object.keys(this.location).forEach(keys => {
            if (keys in res.result.location) {
              this.location[keys] = res.result.location[keys]
            }
          })
          // 当天天气
          Object.keys(this.now).forEach(keys => {
            if (keys in res.result.now) {
              this.now[keys] = res.result.now[keys]
            }
          })
          this.forecasts = res.result.forecasts.slice(0, 4)
          this.forecasts.forEach(i => {
            i.week = i.week.replace(/星期/g, '周')
          })
          console.log(this.forecasts)
        }).catch(err => { console.log(err) })
      },

      getCity() {
        this.$nextTick(() => {
          MP(this.ak).then(BMap => {
            var that = this
            console.log(BMap)
            var myCity = new BMap.LocalCity();
            myCity.get(function (result) {
              result.name && that.getWeather(result.name)
            })
          })
        })
      },

      clickWeather(v) {
        this.checked = v
      }



    }
  }
</script>
<style scoped lang="scss">
  .plates {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    .plate-left {
      width: 45%;
      height: 100%;
      border-radius: 25px 0 0 25px;
      color: #fff;
      box-sizing: border-box;
      padding: 3% 5%;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      position: relative;
      z-index: 99;

      .pl-top {
        width: 100%;
        height: 40%;
      }

      .pl-bottom {
        width: 100%;
        height: 60%;
      }
    }

    .plate-left::before {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url('../../static/pictrue/001s.jpg');
      background-repeat: no-repeat;
      background-position: center top;
      filter: blur(2px);
      z-index: -199;
    }

    .plate-right {
      width: 55%;
      height: 100%;
      background-color: #222831;
      border-radius: 0 25px 25px 0;
      box-sizing: border-box;
      padding: 3% 5%;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      color: #fff;

      .pr-top {
        width: 100%;
        height: 90%;

        .pr-weatherbox {
          width: 100%;
          height: 35%;
          margin-top: 1vh;
          display: flex;

          .weather {
            width: 25%;
            height: 100%;
            background-color: #272e37;
            border-radius: 10px;
            text-align: center;
            box-sizing: border-box;
            padding: 2%;
          }

          .checked-weather {
            background-color: #fff;
            color: #000;
          }

        }
      }

      .pr-bottom {
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .mybutton {
          width: 80%;
          height: 100%;

          .pr-button {
            height: 100%;
            width: 100%;
            border-radius: 10px;
            background: linear-gradient(to right, #83c4cf, #505ed3);
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }



  .pl-font1 {
    font-size: 6vh;
  }

  .pl-font2 {
    font-size: 3.5vh;
    line-height: 1.5;
  }

  .pl-font3 {
    font-size: 3vh;
    line-height: 1.8;
  }

  .pl-font5 {
    font-size: 1vh;
    line-height: 2;
  }

  .pr-font1 {
    font-size: 7vh;
  }

  .pr-font2 {
    font-size: 2.5vh;
    line-height: 2.5;
  }

  .pr-font3 {
    font-size: 2.2vh;
    line-height: 2;
  }


  .pr-item {
    font-size: 3vh;
    line-height: 2.2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }



  .bolder {
    font-weight: 600;
  }

  .letter {
    letter-spacing: 1vh;
  }
</style>