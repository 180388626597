import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)


const routes = [
  { path: '/', redirect: '/envir' },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/envir',
    name: 'envir',
    meta: {index: 0},
    component: () => import('../views/components/envir.vue')
  },
  {
    path: '/health',
    name: 'health',
    meta: {index: 2},
    component: () => import('../views/components/health.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: {index: 1},
    component: () => import('../views/components/home.vue')
  },
  {
    path: '/rescue',
    name: 'rescue',
    meta: {index: 3},
    component: () => import('../views/components/rescueplus.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
