<template>
    <div style="width: 100%; height: 100%;">
        <div class="plates">

            <div class="plate" :style="{backgroundColor: alarmType.smoke == 0?'#f2c94c':'#ef796b'}">
                <div class="plate-box center">
                    <div class="plate-item-box center">
                        <div class="plate-item">
                            <div class="iconfont icon-yangan"
                                :style="{fontSize: '6vh', width: '100%', textAlign: 'center', color: alarmType.smoke == 0?'#735806':'#B42424'}">
                            </div>
                            <div class="plate-text" :style="{color: alarmType.smoke == 0?'#735806':'#B42424'}">烟感报警
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plate" :style="{backgroundColor: alarmType.sos == 0?'#f2c94c':'#ef796b'}">
                <div class="plate-box center">
                    <div class="plate-item-box center">
                        <div class="plate-item">
                            <div class="iconfont icon-SOS"
                                :style="{fontSize: '7vh', width: '100%', textAlign: 'center', color: alarmType.sos == 0?'#735806':'#B42424'}">
                            </div>
                            <div class="plate-text" :style="{color: alarmType.sos == 0?'#735806':'#B42424'}">sos拉绳报警
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plate" :style="{backgroundColor: alarmType.door == 0?'#f2c94c':'#ef796b'}">
                <div class="plate-box center">
                    <div class="plate-item-box center">
                        <div class="plate-item">
                            <div class="iconfont icon-menci"
                                :style="{fontSize: '8vh', width: '100%', textAlign: 'center', color: alarmType.door == 0?'#735806':'#B42424'}">
                            </div>
                            <div class="plate-text" :style="{color: alarmType.door == 0?'#735806':'#B42424'}">门磁报警</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plate" :style="{backgroundColor: alarmType.gas == 0?'#f2c94c':'#ef796b'}">
                <div class="plate-box center">
                    <div class="plate-item-box center">
                        <div class="plate-item">
                            <div class="iconfont icon-ranqibaojingqi"
                                :style="{fontSize: '8vh', width: '100%', textAlign: 'center', color: alarmType.gas == 0?'#735806':'#B42424'}">
                            </div>
                            <div class="plate-text" :style="{color: alarmType.gas == 0?'#735806':'#B42424'}">可燃气体报警
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plate" :style="{backgroundColor: alarmType.infrared == 0?'#f2c94c':'#ef796b'}">
                <div class="plate-box center">
                    <div class="plate-item-box center">
                        <div class="plate-item">
                            <div class="iconfont icon-hongwai"
                                :style="{fontSize: '7vh', width: '100%', textAlign: 'center', color: alarmType.infrared == 0?'#735806':'#B42424'}">
                            </div>
                            <div class="plate-text" :style="{color: alarmType.infrared == 0?'#735806':'#B42424'}">红外报警
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plate" :style="{backgroundColor: alarmType.water == 0?'#f2c94c':'#ef796b'}">
                <div class="plate-box center">
                    <div class="plate-item-box center">
                        <div class="plate-item">
                            <div class="iconfont icon-shuijinbaojing"
                                :style="{fontSize: '7vh', width: '100%', textAlign: 'center', color: alarmType.water == 0?'#735806':'#B42424'}">
                            </div>
                            <div class="plate-text" :style="{color: alarmType.water == 0?'#735806':'#B42424'}">水浸报警
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import { alarmStatus } from '../../network/api.js'
    export default {
        created() {
            this.getAlarmType()
            this.timer = setInterval(() => {
                this.getAlarmType()
            }, 5 * 1000)

        },
        destroyed() {
            clearInterval(this.timer)
        },
        data() {
            return {
                alarmType: {
                    infrared: 0, //红外
                    door: 0, //门磁
                    gas: 0, //可燃气
                    sos: 0, //拉绳
                    smoke: 0, //烟感
                    water: 0, //水浸
                },
                timer: null,
                sound: new Audio(require('../../static/alarm.mp3')),
                sounding: false
            }
        },
        watch:{
            sounding: {
                handler(newVal, oldVal) {
                    console.log(newVal)
                    if(newVal == true && oldVal == false){
                        console.log(111)
                        this.actionSound()
                    }else{
                        this.stopSound()
                    }
                },
                deep: true,
                immediate: true
            }
        },
        methods: {
            getAlarmType() {
                alarmStatus().then(res => {
                    let data = res.result
                    let alarmType = {
                        infrared: 0, //红外
                        door: 0, //门磁
                        gas: 0, //可燃气
                        sos: 0, //拉绳
                        smoke: 0, //烟感
                        water: 0, //水浸
                    }
                    for (var i = 0; i < data.length; i++) {
                        Object.keys(this.alarmType).forEach(keys => {
                            if (data[i].equipTypeNo.indexOf(keys) !== -1) {
                                if (data[i].status > 0) {
                                    alarmType[keys] = data[i].status
                                }

                            }
                        })
                    }
                    
                    let alarming = Object.values(alarmType).some(value => value > 0)
                    if(alarming){
                        this.sounding = true
                    }else{
                        this.sounding = false
                    }
                    Object.assign(this.alarmType, alarmType)
                    console.log(this.sounding)
                }).catch(err => {
                    console.log(err)
                })
            },
            actionSound() {
                if(this.sound.paused){
                    this.sound.play();
                }
                
                // this.sound.loop = true;
            },
            stopSound() {
                this.sound.pause();
                this.sound.currentTime = 0;
                
            }
        }
    }
</script>
<style lang="scss" scoped>
    .plates {
        width: 100%;
        height: 100%;

        .plate {
            width: 33%;
            height: 49%;
            margin-left: 0.5%;
            margin-bottom: 0.5%;
            border-radius: 10px;
            display: inline-block;
            vertical-align: middle;

            .plate-box {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;

                .plate-item-box {
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;

                    .plate-item {
                        width: 100%;
                        height: 50%;
                        display: flex;
                        flex-wrap: wrap;
                        align-content: space-between;

                        .plate-text {
                            white-space: nowrap;
                            color: #735806;
                            font-weight: 600;
                            font-size: 4vh;
                            width: 100%;
                            text-align: center;
                        }
                    }


                }
            }

        }
    }

    .plate:nth-child(3n - 2) {
        margin-left: 0;
    }

    .center {
        justify-content: center !important;
        align-items: center !important;
    }
</style>