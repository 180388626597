<template>
    <div class="kk">
        
    </div>
</template>
<script>
    export default {
        name: 'home'
    }
</script>
<style scoped lang="scss">
    .kk{
        width: 100%;
        height: 100%;
        background-image: url('../../static/pictrue/088.png');
        background-position: center center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
</style>