<template>
  <div class="home">
    <el-container style="width: 100%; height: 100%;">
      <el-aside width="220px">

        <img src="../static/pictrue/app (2).png" alt="" class="logo">

        <div class="list">
          <div v-for="(item, index) in title" :key="index" @click="checkItem(index,item.name)" :class="checkItemStyle(index)">
            <div class="list-item-title">
              <div class="lit-text">{{item.text}}</div>
              <div class="lit-subtext">{{item.subtext}}</div>
            </div>
          </div>
        </div>
      </el-aside>
      <el-container>
        <el-header>
          <div class="header">
            <div class="search">
              <el-input v-model="input" placeholder="What are you looking for?">
                <template slot="suffix">
                  <div style="height: 100%; line-height: 40px">
                    <span
                      style="display: inline-block; margin-right: 20px; font-size: 16px; color: #7e819d;">Search</span>
                    <i class="el-icon-search" style="font-size: 16px; margin-right: 15px; color: #7e819d;"></i>
                  </div>
                </template>
              </el-input>
            </div>
            <div class="tool">
              <div class="fonts">
                <div :class="controlVision" @click="vision = !vision"></div>
                <div :class="controlSound" @click="sound = !sound"></div>
              </div>
            </div>
            <div class="login-box">
              <div class="login">
                <div>登录</div>
                <div>注册</div>
              </div>
            </div>
            <div class="avatar-box">
              <div class="avatar">
                <el-avatar :size="70" src="https://pic.616pic.com/ys_bnew_img/00/10/46/m5E7WX7tMf.jpg"
                  fit="cover"></el-avatar>
                <div class="online"></div>
              </div>
            </div>
          </div>
        </el-header>
        <el-main>
          <envir v-if="checkIndex == 0"/>
          <home v-if="checkIndex == 1"/>
          <health v-if="checkIndex == 2"/>
          <rescue v-if="checkIndex == 3"/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import rescue from './components/rescueplus'
import home from './components/home'
import health from './components/health'
import envir from './components/envir'
  export default {
    components:{
      envir,
      health,
      home,
      rescue
    },
    data() {
      return {
        title: [
          { text: '周边环境', subtext: 'Climate Date', name: 'envir' },
          { text: '智慧家庭', subtext: 'Smart Home', name: 'home' },
          { text: '健康管理', subtext: 'Health Care', name: 'health' },
          { text: '紧急救护', subtext: 'Emergency', name: 'rescue' }
        ],
        checkIndex: 0,
        input: '',
        vision: true,
        sound: true
      }
    },
    computed: {
      checkItemStyle() {
        return (index) => {
          return index === this.checkIndex ? 'checked list-item' : 'list-item'
        }
      },
      controlVision() {
        return this.vision ? 'iconfont icon-xianshi myfont1' : 'iconfont icon-yincang myfont1'
      },
      controlSound() {
        return this.sound ? 'iconfont icon-SpeakerLow myfont2' : 'iconfont icon-SpeakerX myfont2'
      }
    },
    methods: {
      checkItem(index,name) {
        this.checkIndex = index
        console.log(name)
        this.$router.push(name)
      }
    }
  }
</script>
<style scoped lang="scss">
  .home {
    width: 100vw;
    height: 100vh;
  }

  .el-header {
    height: 140px !important;
  }

  .el-main {
    /* background-color: #d3dce6; */
  }

  .logo {
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    background-color: aqua;
  }

  .checked {
    color: #fff !important;
    background-color: #ef796b;
  }

  .list {
    width: 100%;
    height: auto;

    .list-item {
      width: 90%;
      height: 50px;
      padding: 10px 0;
      margin: 40px 0;
      text-align: center;
      color: #7e819d;
      cursor: pointer;
      border-radius: 0 15px 15px 0;
      position: relative;

      .list-item-title {
        width: fit-content;
        position: absolute;
        top: 50%;
        right: 20%;
        transform: translateY(-50%);

        .lit-text {
          font-size: 25px;
        }

        .lit-subtext {
          font-size: 12px;
        }
      }
    }

    .list-item:first-child {
      margin-top: 20px;
    }
  }

  .header {
    height: 100%;
    width: 100%;
    position: relative;

    .search {
      width: 45%;
      position: absolute;
      top: 50%;
      left: 6%;
      transform: translateY(-50%);
    }

    .tool {
      width: 100px;
      height: 50px;
      position: absolute;
      top: 50%;
      right: 32%;
      transform: translateY(-50%);

      .fonts {
        width: 100%;
        height: 100%;
        position: relative;
      }

      .myfont1 {
        color: #8ca8e0;
        font-size: 35px;
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translateY(-50%);
      }

      .myfont2 {
        color: #8ca8e0;
        font-size: 30px;
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-50%);
      }
    }

    .login-box {
      position: absolute;
      top: 50%;
      right: 15%;
      transform: translateY(-50%);

      .login {
        display: flex;
        width: 100px;
        justify-content: space-between;
        color: #2f5ebf;
        font-weight: 600;
        position: relative;
      }

      .login::before {
        content: '';
        height: 21px;
        width: 0;
        border: 1px #2f5ebf solid;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .avatar-box {
      position: absolute;
      top: 50%;
      right: 1%;
      transform: translateY(-50%);

      .avatar {
        width: 100%;
        height: 100%;
        position: relative;

        .online {
          width: 8px;
          height: 8px;
          background-color: #3377ff;
          border-radius: 50%;
          position: absolute;
          top: 10%;
          left: 12%;
        }
      }
    }
  }



  /* 深度修改样式 */
  .el-input ::v-deep .el-input__inner {
    border-radius: 20px !important;
  }

  .el-input ::v-deep .el-input__inner::placeholder {
    color: #7e819d !important;
  }

  .el-input ::v-deep .el-input__inner:focus {
    border-color: #184db9 !important;
    border-width: 2px;
    box-shadow: 0px 0px 5px #b2afaf;
  }

  .el-input ::v-deep .el-input--suffix .el-input__inner {
    padding-right: 120px;
  }
</style>