import axios from 'axios';

let BASE_URL = ''
if (process.env.NODE_ENV == 'development') {
  // BASE_URL = 'http://192.168.9.104:8080/elderly-care' // 开发环境
BASE_URL = 'https://rende.ailaowuyou.com/elderlycare'
} else {
BASE_URL = 'https://rende.ailaowuyou.com/elderlycare' // 生产环境
}
// 创建 axios 实例
const instance = axios.create({
  baseURL: BASE_URL, // 基本 URL
  timeout: 5000, // 超时时间
});

// 封装 Http 对象
const Http = {
  get: (url, params) => {
    return instance.get(url, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error));
  },
  
  post: (url, data) => {
    return instance.post(url, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error));
  },
};

export default Http;