<template>
    <div style="width: 100%; height: 100%;">
        <div class="platebox">
            <div class="plate-left">
                <div class="pl-top">
                    <div class="plt-left">
                        <div class="pltl-top">
                            <div class="iconfont icon-tianjiayonghu" style="color: #f2796b; font-size: 5vh;"></div>
                            <div class="font3">健康指导</div>
                        </div>
                        <div class="pltl-bottom">
                            <div class="pltlb-left"></div>
                            <div class="pltlb-right">
                                <div>
                                <div style="font-size: 2.5em;">清醒次数 2次</div>
                                <div class="grey" style="font-size: 2em;">参考值:0-1次</div>
                                <div style="font-size: 2.5em;">深睡连续性 74分</div>
                                <div class="grey" style="font-size: 2em;">参考值:70-100分</div>
                                <div style="font-size: 2.5em;">浅睡比例 50%</div>
                                <div class="grey" style="font-size: 2em;">参考值:小于55%</div>
                                <div style="font-size: 2.5em;">呼吸质量 98分</div>
                                <div class="grey" style="font-size: 2em;">参考值:70-100分</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="plt-right">
                        <div class="font3">82分</div>
                        <el-rate v-model="value1"></el-rate>
                        <div class="grey" style="font-size: 2em;">超越了92%的用户</div>
                        <div style="font-size: 2em; color: red;">昨晚睡眠质量较好，然而，夜醒了两次，高于正常范围，长期容易醒会造成心脏压力大，引发高血压</div>
                    </div>
                </div>
                <div class="pl-bottom">
                    <div class="plb-top">
                        <div class="iconfont icon-qingjing_shuimian" style="color: #b1c967; font-size: 5vh;"></div>
                        <div class="plbt-box">
                            <div class="plbt-box-1" style="font-size: 2em; color: #b1c967;">夜间睡眠</div>
                            <div class="plbt-box-2 bolder" style="font-size: 3em; color: #f27d70;">6小时40分钟</div>
                            <div class="plbt-box-3 grey" style="font-size: 2em;">8月25日 周五</div>
                        </div>
                    </div>
                    <div class="plb-center"></div>
                    <div class="plb-bottom">
                        <div class="plbb-box">
                            <div class="grey" style="font-size: 2em; width: 100%;">3小时20分钟</div>
                            <div style="width: 100%;"><span class="circle"></span><span>浅睡</span></div>
                        </div>
                        <div class="plbb-box">
                            <div class="grey" style="font-size: 2em; width: 100%;">3小时20分钟</div>
                            <div style="width: 100%;"><span class="circle" style="background: #f2796b;"></span><span>深睡</span></div>
                        </div>
                        <div class="plbb-box">
                            <div class="grey" style="font-size: 2em; width: 100%;">3小时20分钟</div>
                            <div style="width: 100%;"><span class="circle" style="background-color: #bad9ee;"></span><span>清醒</span></div>
                        </div>
                        <div class="plbb-box">
                            <div class="grey" style="font-size: 2em; width: 100%;">3小时20分钟</div>
                            <div style="width: 100%;"><span class="circle" style="background-color: #ffe766;"></span><span>起夜</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plate-right">
                <div class="plr-top">
                    <div class="plrt-box1 bolder font3" style="color: #b1c967;">起夜运动轨迹</div>
                    <div class="plrt-box2"></div>
                    <div class="plrt-box3" style="font-size: 1vh;">
                        <div style="aspect-ratio: 1; height: 60%; background-color: #f2796b; border-radius: 50%; color: #fff; text-align: center; font-size: 2em; ">2</div>
                        <div style="aspect-ratio: 1; height: 60%; background-color: #b1c967; border-radius: 50%; color: #fff; text-align: center; font-size: 2em; ">1</div>
                        <div class="font2 grey" style="margin-right: 1vh;">夜灯</div>
                    </div>
                </div>
                <div class="plr-bottom">
                    <div class="plrb-top">
                        <div class="bolder font3" style="color: #b1c967;">起夜统计</div>
                        <div style="width: 65%;"> <el-date-picker style="width: 100%;"
                            v-model="value2"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                          </el-date-picker></div>
                    </div>
                    <div class="plrb-center">
                        <div class="plrbc-box1"></div>
                        <div class="plrbc-box2">
                            <div class="plrbc-item" style="font-size: 1vh; line-height: 1.8;">
                                <div class="bolder" style="font-size: 2em; color: #6c8da0;">9:00PM - 11:00PM</div>
                                <div class="bolder" style="font-size: 2em; color: #3395cb;">8%</div>
                            </div>
                            <div class="plrbc-item" style="font-size: 1vh; line-height: 1.8;">
                                <div class="bolder" style="font-size: 2em; color: #6c8da0;">11:00AM - 1:00AM</div>
                                <div class="bolder" style="font-size: 2em; color: #3395cb;">25%</div>
                            </div>
                            <div class="plrbc-item" style="font-size: 1vh; line-height: 1.8;">
                                <div class="bolder" style="font-size: 2em; color: #6c8da0;">1:00AM - 3:00AM</div>
                                <div class="bolder" style="font-size: 2em; color: #3395cb;">35%</div>
                            </div>
                            <div class="plrbc-item" style="font-size: 1vh; line-height: 1.8;">
                                <div class="bolder" style="font-size: 2em; color: #6c8da0;">3:00AM - 4:00AM</div>
                                <div class="bolder" style="font-size: 2em; color: #3395cb;">12%</div>
                            </div>
                            <div class="plrbc-item" style="font-size: 1vh; line-height: 1.8;">
                                <div class="bolder" style="font-size: 2em; color: #6c8da0;">4:00AM - 7:00AM</div>
                                <div class="bolder" style="font-size: 2em; color: #3395cb;">20%</div>
                            </div>
                        </div>
                    </div>
                    <div class="plrb-bottom">
                        <div class="plrbb-box" style="font-size: 1vh;">
                            <div style="font-size: 1.5em; color: #6c8da0; transform: scale(0.8); white-space: nowrap;">8月份最多起夜时间段</div>
                            <div class="bolder" style="font-size: 2em; color: #6c8da0;">1:00AM - 3:00AM</div>
                        </div>
                        <div class="plrbb-box" style="font-size: 1vh;">
                            <div style="font-size: 2em; color: #6c8da0; transform: scale(0.8); white-space: nowrap;">8月份最少起夜时间段</div>
                            <div class="bolder" style="font-size: 2em; color: #6c8da0;">9:00AM - 11:00AM</div>
                        </div>
                        <div class="plrbb-box" style="font-size: 1vh;">
                            <div style="font-size: 2em; color: #f27460; transform: scale(0.8); white-space: nowrap;">单月共计</div>
                            <div class="bolder" style="font-size: 3em; color: #f27460;">12次</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'health',
        data() {
            return {
                value1: 4,
                value2: ''
            }
        },
    }
</script>
<style scoped lang="scss">
    :root{
        font-size: 12px;
    }
    .circle{
        height: 1.5vh;
        aspect-ratio: 1;
        border-radius: 50%;
        display: inline-block;
        margin-right: 1vh;
        background-color: #b1c967;
    }
    .platebox{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .plate-left{
            width: 51%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            .pl-top{
                width: 100%;
                height: 49%;
                background-color: #f4f4f4;
                border-radius: 25px;
                box-sizing: border-box;
                padding: 3%;
                display: flex;
                .plt-left{
                    width: 65%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: space-between;
                    .pltl-top{
                        width: 55%;
                        height: 20%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    .pltl-bottom{
                        width: 100%;
                        height: 80%;
                        display: flex;
                        .pltlb-left{
                            height: 100%;
                            width: 50%;
                            background-image: url('../../static/pictrue/004.png');
                            background-position: center center;
                            background-size: 100%;
                            background-repeat: no-repeat;
                        }
                        .pltlb-right{
                            height: 100%;
                            width: 50%;
                            font-size: 1vh;
                            text-align: left;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
                .plt-right{
                    width: 35%;
                    height: 100%;
                    background-color: #fff;
                    border-radius: 20px;
                    box-sizing: border-box;
                    padding: 3%;
                    font-size: 1vh;
                }
            }
            .pl-bottom{
                width: 100%;
                height: 48.5%;
                background-color: #fdf0ed;
                border-radius: 25px;
                box-sizing: border-box;
                padding: 3%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .plb-top{
                    width: 70%;
                    height: 20%;
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    .plbt-box{
                        width: 80%;
                        font-size: 1vh;
                        position: relative;
                        .plbt-box-1{
                            position: absolute;
                            top: 0;
                            left: 0;                       
                        }
                        .plbt-box-2{
                            position: absolute;
                            top: -10%;
                            left: 30%;                       
                        }
                        .plbt-box-3{
                            position: absolute;
                            bottom: 10%;
                            left: 0;                       
                        }
                    }
                }
                .plb-center{
                    width: 100%;
                    height: 60%;
                    border-radius: 20px;
                    background-image: url('../../static/pictrue/006.png');
                    background-position: center center;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
                .plb-bottom{
                    width: 100%;
                    height: 20%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    font-size: 1vh;
                    line-height: 1.5;
                    .plbb-box{
                        flex: 1;
                        text-align: left;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-content: flex-end;
                    }
                }
            }
        }
        .plate-right{
            width: 48%;
            height: 100%;
            background-color: #f4f4f4;
            border-radius: 25px;
            display: flex;
            box-sizing: border-box;
            padding: 3%;
            flex-wrap: wrap;
            align-content: space-between;
            .plr-top{
                height: 49%;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
                .plrt-box1{
                    height: 13%;
                    width: 100%;
                }
                .plrt-box2{
                    height: 74%;
                    width: 100%;
                    border-radius: 20px;
                    background-image: url('../../static/pictrue/012.png');
                    background-position: center center;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
                .plrt-box3{
                    height: 13%;
                    width: 100%;
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                }
            }
            .plr-bottom{
                height: 49%;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                .plrb-top{
                    height: 15%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }
                .plrb-center{
                    height: 65%;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    margin-top: 1vh;
                    .plrbc-box1{
                        width: 40%;
                        height: 100%;
                        background-image: url('../../static/pictrue/011.png');
                        background-position: center center;
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                    }
                    .plrbc-box2{
                        width: 58%;
                        height: 100%;
                        .plrbc-item{
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }
                }
                .plrb-bottom{
                    height: 20%;
                    width: 100%;
                    background-color: #ebefe0;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .plrbb-box{
                        flex: 1;
                        display: flex;
                        flex-wrap: wrap;
                        align-content: center;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }

    .font1{
        font-size: 1vh;
    }
    .font1-5{
        font-size: 1.5vh;
    }
    .font2{
        font-size: 2vh;
    }
    .font2-5{
        font-size: 2.5vh;
    }
    .font3{
        font-size: 3vh;
    }
    .font3-5{
        font-size: 3.5vh;
    }
    .font4{
        font-size: 4vh;
    }
    .bolder{
        font-weight: 600;
    }
    .grey{
        color: grey;
    }
    
    .el-rate ::v-deep .el-rate__icon{
        font-size: 1vh !important;
    }
</style>