import  Http  from './http.js';
const Api = {
    alarm: '/equipment/eqAlarm/alarmStatus',
    forecast: '/xiaodu/weather/forecast-xd'
}
export const alarmStatus = (params) => {
  return Http.get(Api.alarm, params)
}
export const forecast = (params) => {
  return Http.get(Api.forecast, params)
}